import LaunchOutlinedIcon from '@mui/icons-material/LaunchOutlined'
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'
import { FC, useState } from 'react'
import { IModalWithCloseFn } from '@/types/interfaces/ui'
import { Modal } from '@/components/organisms'
import { Button, Col, Row, Text } from '@/components/atoms'
import { TextTypes } from '@/types/enums/ui'
import { Color } from '@/styles/palette'
import { DOCUMENTATION_URL, SUPPORT_EMAIL } from '@/constants'

import styles from './ContactSupportModal.module.scss'

const ContactSupportModal: FC<IModalWithCloseFn> = (props) => {
  const { closeModal } = props

  const [emailCopied, setEmailCopied] = useState<boolean>(false)

  const onCopyEmail = () => {
    navigator.clipboard.writeText(SUPPORT_EMAIL).then(() => {
      setEmailCopied(true)
    })
  }

  return (
    <Modal
      size="lg"
      title="Contact Support"
      placement="center"
      closeModal={closeModal}
      footer={
        <Button
          type="outlined"
          href={DOCUMENTATION_URL}
          endIcon={<LaunchOutlinedIcon />}
        >
          View Support Articles
        </Button>
      }
    >
      <Col gap={16}>
        <Text type={TextTypes.TEXT_MD} color={Color.gray700}>
          If you have questions, need assistance, or want to share feedback,
          we're here to help! We will try out best to respond in 24 hours.
        </Text>

        <Row items="center" gap={8} className={styles.textWithAction}>
          <Text type={TextTypes.TEXT_MD} color={Color.gray700}>
            Email Us: <b className={styles.emailHolder}>{SUPPORT_EMAIL}</b>
          </Text>

          <Button
            small
            type="outlined"
            onClick={onCopyEmail}
            disabled={emailCopied}
            startIcon={
              emailCopied ? <CheckOutlinedIcon /> : <ContentCopyOutlinedIcon />
            }
            className="tw-whitespace-nowrap"
          >
            {emailCopied ? 'Email Copied' : 'Copy Email'}
          </Button>
        </Row>
      </Col>
    </Modal>
  )
}

export default ContactSupportModal
