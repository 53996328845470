import KeyboardDoubleArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowLeftOutlined'
import { Fade } from '@mui/material'
import clsx from 'clsx'
import { FC, MouseEvent } from 'react'

import { Avatar, Col, Row, Text } from '@/components/atoms'
import { CollapsedNavigationPopover } from '@/components/organisms/Navigation/components'
import { UserDetails } from '@/features/auth/types'
import { useStore } from '@/store'
import { Color } from '@/styles/palette'
import { TextTypes } from '@/types/enums/ui'
import { onEnterPressed } from '@/utils/helpers'

import styles from './NavigationFooter.module.scss'
import { INavigationItem } from '@/types/interfaces/ui'

interface IProps {
  collapsed: boolean
  items: INavigationItem[]
  anchorEl: HTMLElement | null
  onClick: (e: MouseEvent<HTMLElement>) => void
  onClose: () => void
  toggleCollapsed: () => void
}

const NavigationFooter: FC<IProps> = (props) => {
  const { collapsed, items, anchorEl, onClick, onClose, toggleCollapsed } =
    props

  const { me = {} } = useStore((state) => state.user)
  const { name = '' } = me as UserDetails

  return (
    <Col
      gap={20}
      items="stretch"
      className={clsx(styles.navFooter, collapsed && styles.collapsed)}
    >
      <Row
        items="center"
        gap={8}
        tabIndex={0}
        clickable
        className={styles.collapseButton}
        onKeyDown={(e) => onEnterPressed(e, toggleCollapsed)}
        onClick={toggleCollapsed}
      >
        <KeyboardDoubleArrowLeftOutlinedIcon />

        <Fade in={!collapsed} timeout={500} appear={false}>
          <div>
            <Text type={TextTypes.TEXT_SM} color={Color.gray300}>
              Collapse
            </Text>
          </div>
        </Fade>
      </Row>

      <Row
        clickable
        gap={8}
        items="center"
        onClick={onClick}
        className={styles.userInfo}
      >
        <Avatar name={name} size="md" />

        <Fade in={!collapsed} timeout={500} appear={false}>
          <div>
            <Text
              type={TextTypes.TEXT_SM}
              color={Color.gray300}
              className={clsx(
                name.includes(' ') ? styles.wrap : styles.ellipsis
              )}
            >
              {name}
            </Text>
          </div>
        </Fade>
      </Row>

      <CollapsedNavigationPopover
        title={name}
        anchorEl={anchorEl}
        name="popover-user-profile"
        className="!tw-ml-[40px]"
        Icon={<Avatar name={name} size="md" />}
        onClose={onClose}
        items={items}
      />
    </Col>
  )
}

export default NavigationFooter
