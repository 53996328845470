import { useMemo, useState } from 'react'
import { useQuery } from '@apollo/client'

import { GetGateTransactionsQuery } from '@/features/gate/api/queries'
import {
  OrderByDomainEventColumn,
  SearchDomainEventColumns,
  StringOperator
} from '@/__generated__/graphql'

import GateTransactions from '@/assets/icons/gate_transactions.svg?react'
import { Table } from '@/components/organisms'
import { PAGINATION_ITEMS_PER_PAGE } from '@/constants'
import { useFetchAccountsQuery } from '@/features/gate/api'
import {
  transactionsColumns,
  transactionsHighOrderColumns
} from '@/features/gate/utils'
import { useStore } from '@/store'

import styles from './GateTransactionTemplate.module.scss'
import { TableRequestDetails } from '@/types/interfaces/table'
import { prepareSortForGraphQlApi } from '@/api'
import {
  accountFilter,
  appointmentTypeFilter,
  dateRangeFilter,
  emissionTypeFilter,
  eventTypeFilter,
  fuelTypeFilter,
  loadStatusFilter,
  mismatchFilter,
  transactionDirectionFilter,
  weightClassFilter
} from '@/utils/table'

const GateTransactionTemplate = () => {
  const { selectedPortal, org } = useStore((store) => store.user)

  const site_id = selectedPortal?.id || ''
  const org_id = org?.organization_id || ''

  const [
    { currentPage, search, sortBy, filters, dateRange },
    setRequestDetails
  ] = useState<TableRequestDetails>({
    currentPage: 1,
    search: '',
    sortBy: undefined,
    filters: undefined,
    dateRange: undefined
  })

  const { loading, data } = useQuery(GetGateTransactionsQuery, {
    variables: {
      input: {
        organizationID: org_id,
        siteID: site_id,
        currentPage,
        itemsPerPage: PAGINATION_ITEMS_PER_PAGE,
        search: {
          fields: [
            SearchDomainEventColumns.PowerUnitOwnerId,
            SearchDomainEventColumns.PowerUnitLicensePlateNumber,
            SearchDomainEventColumns.PowerUnitCarrierUsdot,
            SearchDomainEventColumns.PowerUnitCarrierMcnum,
            SearchDomainEventColumns.PowerUnitVin,
            SearchDomainEventColumns.PowerUnitCarrierName,
            SearchDomainEventColumns.AccountName,
            SearchDomainEventColumns.DriverName,
            SearchDomainEventColumns.DriverLicenseNumber,
            SearchDomainEventColumns.DriverPhoneNumber,
            SearchDomainEventColumns.CargoAssetOwnerId,
            SearchDomainEventColumns.CargoAssetLicensePlateNumber,
            SearchDomainEventColumns.CargoAssetCarrierName,
            SearchDomainEventColumns.ShipmentNumber,
            SearchDomainEventColumns.SealNumbers
          ],
          operator: StringOperator.Contains,
          value: search
        },
        order: prepareSortForGraphQlApi<OrderByDomainEventColumn>(sortBy),
        filter: filters,
        dateRange
      }
    }
  })

  const { data: accounts = [] } = useFetchAccountsQuery({
    site_id,
    org_id
  })

  const filtersList = useMemo(
    () => [
      dateRangeFilter,
      transactionDirectionFilter,
      eventTypeFilter,
      appointmentTypeFilter,
      weightClassFilter,
      fuelTypeFilter,
      emissionTypeFilter,
      loadStatusFilter,
      accountFilter(accounts),
      mismatchFilter
    ],
    [accounts]
  )

  const transactions = data?.listGateTransactions?.events || []

  return (
    <div className={styles.gateTransactionTemplate}>
      <div className="tw-flex-1 tw-flex">
        <Table
          withSearch
          name="gate-transactions"
          idAccessor="id"
          loading={loading}
          rows={transactions}
          filters={filtersList}
          columns={transactionsColumns}
          highOrderColumns={transactionsHighOrderColumns}
          searchPlaceholder="Search Transaction"
          perPage={PAGINATION_ITEMS_PER_PAGE}
          currentPage={currentPage}
          totalPages={data?.listGateTransactions?.pagination?.totalPages}
          totalItems={data?.listGateTransactions?.pagination?.totalItemsCount}
          onUpdateRequestDetails={setRequestDetails}
          noResultsMessage={{
            primaryText: 'No gate transactions',
            descriptionText:
              'Gate Check-In and Check-Out transactions will appear here.',
            Icon: <GateTransactions />
          }}
        />
      </div>
    </div>
  )
}

export default GateTransactionTemplate
