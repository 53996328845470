import clsx from 'clsx'
import { FC, MouseEvent, useEffect, useMemo, useState } from 'react'
import { useLocalStorage, useWindowSize } from 'usehooks-ts'

import { useMobileNavigationContext } from '@/components/contexts'
import { BREAKPOINTS } from '@/constants'
import { useDispatch, useStore } from '@/store'
import { navigationItems } from '@/utils/data/navigationItems'

import {
  NavigationFooter,
  NavigationHeader,
  NavigationItem,
  PortalsDropdownList,
  PortalsDropdownListMobile
} from './components'
import styles from './Navigation.module.scss'
import { SidebarScreens } from '@/types/enums/ui'
import { withParams } from '@/api'
import { ContactSupportModal } from '@/features/profile'
import { useModal } from '@/hooks'
import { useAuth0 } from '@auth0/auth0-react'
import { logoutUser } from '@/features/auth/store'
import { ROUTES } from '@/router'

const Navigation: FC = () => {
  const { mobileNavigationOpened, toggleMobileNavigation } =
    useMobileNavigationContext()

  const { width = 0 } = useWindowSize()
  const { selectedPortal, selectedGate } = useStore((store) => store.user)
  const { visible, openModal, closeModal } = useModal<boolean>(false)
  const { logout } = useAuth0()
  const dispatch = useDispatch()

  const [expandedItem, setExpandedItem] = useState<string | undefined>()
  const [screen, setScreen] = useState<SidebarScreens>(SidebarScreens.MenuItems)
  const [profileRowWrapper, setProfileRowWrapper] =
    useState<HTMLElement | null>(null)

  const [collapsed, setCollapsed] = useLocalStorage('sidebarCollapsed', false)

  const isSmallView = width <= BREAKPOINTS.MD
  const isMenuItemsStep = screen === SidebarScreens.MenuItems

  const mainMenuItems = useMemo(() => {
    if (!selectedPortal || !navigationItems) return []

    const { id: gate_id = '' } = selectedGate || {}
    const { id: site_id, type, permissions } = selectedPortal

    return (
      navigationItems
        // Remove high level items that are not available
        .filter((item) => item.visible(type, permissions))
        .map((item) => {
          const { link, items } = item

          if (link)
            return {
              ...item,
              link: withParams(link, {
                site_id,
                gate_id
              })
            }

          // Remove sub-items that are not available
          const availableSubItems = items
            ?.filter((subItem) => subItem.visible(permissions))
            .map((subItem) => ({
              ...subItem,
              link: withParams(subItem.link, {
                site_id,
                gate_id
              })
            }))

          return { ...item, items: availableSubItems || undefined }
        })
    )
  }, [selectedPortal, selectedGate])

  const accountMenuItems = useMemo(
    () => [
      {
        id: 'profile',
        title: 'Profile',
        link: ROUTES.PROFILE,
        visible: () => true
      },
      {
        id: 'contact-support',
        title: 'Contact Support',
        onClick: () => {
          openModal()
          setProfileRowWrapper(null)
        },
        visible: () => true
      },
      {
        id: 'logout',
        title: 'Logout',
        onClick: async () => {
          await logout({
            logoutParams: {
              returnTo: window.location.origin
            }
          })

          dispatch(logoutUser())
        },
        visible: () => true
      }
    ],
    []
  )

  const handleExpandItem = (id: string) => {
    setExpandedItem(id === expandedItem ? undefined : id)
  }

  const toggleCollapsed = () => setCollapsed((prev) => !prev)

  const onProfileClick = (e: MouseEvent<HTMLElement>) => {
    if (isSmallView) {
      setScreen(SidebarScreens.AccountItems)
      return
    }

    setProfileRowWrapper(e.currentTarget)
  }

  useEffect(() => {
    if (isSmallView && collapsed && mobileNavigationOpened) {
      setCollapsed(false)
    }

    // Reset to default sidebar screen when resize from tablet/mobile to desktop
    if (!isSmallView && !isMenuItemsStep) {
      setScreen(SidebarScreens.MenuItems)
    }
  }, [isSmallView, mobileNavigationOpened])

  const PortalPicker = isSmallView ? (
    <PortalsDropdownListMobile />
  ) : (
    <PortalsDropdownList collapsed={collapsed} />
  )

  return (
    <>
      {visible && <ContactSupportModal closeModal={closeModal} />}

      {mobileNavigationOpened && (
        <div
          onClick={() => toggleMobileNavigation()}
          className={styles.blackBackground}
        />
      )}

      <nav
        className={clsx(
          styles.nav,
          collapsed && styles.collapsed,
          mobileNavigationOpened && styles.mobileNavigationOpened
        )}
      >
        <NavigationHeader
          screen={screen}
          setScreen={setScreen}
          collapsed={collapsed}
        />

        {!!selectedPortal?.id && isMenuItemsStep && PortalPicker}

        <div className={styles.navItems}>
          {(isMenuItemsStep ? mainMenuItems : accountMenuItems).map((item) => (
            <NavigationItem
              key={item.id}
              item={item}
              onExpand={handleExpandItem}
              sidebarCollapsed={collapsed}
              isExpanded={expandedItem === item.id}
            />
          ))}
        </div>

        {isMenuItemsStep && (
          <NavigationFooter
            collapsed={collapsed}
            items={accountMenuItems}
            anchorEl={profileRowWrapper}
            onClick={onProfileClick}
            onClose={() => setProfileRowWrapper(null)}
            toggleCollapsed={toggleCollapsed}
          />
        )}
      </nav>
    </>
  )
}

export default Navigation
