import { Table, TableBody, TableContainer } from '@mui/material'
import clsx from 'clsx'
import {
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'

import { Col, Footer, Loader } from '@/components/atoms'
import { TableContextProvider } from '@/components/contexts'
import { MIN_SEARCH_QUERY_LENGTH, PAGINATION_ITEMS_PER_PAGE } from '@/constants'
import {
  TableActions,
  TableFilterType,
  TableSortDirection
} from '@/types/enums/table'
import {
  IDateRangeFilterValue,
  IFilterItem,
  IFilterValues,
  IGroupByItem,
  IHighOrderColumn,
  ITableColumn,
  TableRequestDetails,
  TableRequestDetailsFilters,
  TableSortValue
} from '@/types/interfaces/table'
import { IPagination } from '@/types/interfaces/ui'

import NoSearchResults from '@/assets/icons/no_search_results.svg?react'

import BaseEmptyState, { IBaseEmtpyState } from '../EmptyState/BaseEmptyState'
import {
  TableFilters,
  TableHeader,
  TablePagination,
  TableRow as CustomTableRow
} from './components'
import styles from './Table.module.scss'
import { DateRangeFilter } from '@/__generated__/graphql'
import { Dayjs } from 'dayjs'

interface IProps {
  name: string
  loading?: boolean
  withSearch?: boolean
  columns: (groupBy: string | undefined) => ITableColumn[]
  highOrderColumns?: (groupBy: string | undefined) => IHighOrderColumn[]
  rows: any[]
  idAccessor: string
  clickable?: boolean
  defaultFilters?: IFilterValues
  filters?: IFilterItem[]
  perPage?: number
  currentPage?: number
  totalPages?: number
  totalItems?: number
  onUpdateRequestDetails?: Dispatch<SetStateAction<TableRequestDetails>>
  enablePagination?: boolean
  searchPlaceholder?: string
  groupByOptions?: IGroupByItem[]
  EmptyScreen?: ReactNode
  isRowHighlighted?: (row: any) => boolean
  handleAction?: (action: TableActions, row: any) => void
  noResultsMessage?: IBaseEmtpyState
}

interface PreparedFilters {
  filters: TableRequestDetailsFilters | undefined
  dateRange: DateRangeFilter | undefined
}

const CustomTable: FC<IProps> = (props) => {
  const {
    name,
    columns,
    rows = [],
    idAccessor,
    withSearch = false,
    loading = false,
    currentPage,
    totalPages,
    totalItems,
    onUpdateRequestDetails,
    perPage = PAGINATION_ITEMS_PER_PAGE,
    clickable,
    filters,
    groupByOptions,
    defaultFilters,
    handleAction,
    isRowHighlighted,
    highOrderColumns,
    searchPlaceholder = 'Search',
    enablePagination = true,
    noResultsMessage = {
      primaryText: 'No results found',
      descriptionText: 'Items will appear here once they are added'
    },
    EmptyScreen
  } = props

  const [searchValue, setSearchValue] = useState<string | undefined>()
  const [groupBy, setGroupBy] = useState<string | undefined>()
  const [filterValues, setFilterValues] = useState<IFilterValues>({})

  const [sortBy, setSortBy] = useState<TableSortValue | undefined>()

  const [page, setPage] = useState<number>(currentPage || 1)

  const pagination: IPagination = {
    perPage,
    currentPage: page,
    totalItems: totalItems || rows.length,
    totalPages: totalPages || Math.ceil(rows.length / perPage)
  }

  const memoCols: ITableColumn[] = useMemo(() => columns(groupBy), [groupBy])
  const memoHighOrderCols: IHighOrderColumn[] | undefined = useMemo(
    () => highOrderColumns?.(groupBy),
    [groupBy]
  )

  const memoRows: any[] = useMemo(() => {
    // For client side pagination
    if (rows.length && enablePagination && !onUpdateRequestDetails) {
      const start = (pagination.currentPage - 1) * pagination.perPage
      const end = Math.min(
        pagination.currentPage * pagination.perPage,
        pagination.totalItems
      )

      return rows.slice(start, end)
    }

    return rows
  }, [rows, enablePagination, pagination])

  const { showFilters, showSearch, hasRows, isEmpty, isFilterApplied } =
    useMemo(() => {
      const isFilterApplied =
        Object.values(filterValues || {}).some(
          (filterValue) => !!filterValue
        ) || !!searchValue

      return {
        isFilterApplied,
        showSearch: withSearch,
        showFilters:
          withSearch || Array.isArray(groupByOptions) || Array.isArray(filters),

        hasRows: !!memoRows.length && !loading,
        isEmpty: !memoRows.length && !isFilterApplied && !loading
      }
    }, [filterValues, searchValue, memoRows, loading, withSearch])

  const onFilterChange = (filters: IFilterValues) => {
    setFilterValues(filters)

    const preparedFilters: PreparedFilters = Object.entries(filters).reduce(
      (acc: PreparedFilters, [key, value]) => {
        const filter = props.filters?.find((filter) => filter.id === key)

        if (!value) return acc

        switch (filter?.type) {
          case TableFilterType.List: {
            acc.filters = {
              ...(acc.filters || {}),
              [key]: filter.requestFormatter(value as string[])
            }

            break
          }

          case TableFilterType.DateRange: {
            acc.dateRange = filter.requestFormatter(
              value as IDateRangeFilterValue
            )
            break
          }
          case TableFilterType.Dwell: {
            acc.dateRange = filter.requestFormatter(
              filterValues?.dateRange,
              value as Dayjs
            )
            break
          }

          default: {
            break
          }
        }

        return acc
      },
      { filters: undefined, dateRange: undefined } as PreparedFilters
    )

    onUpdateRequestDetails?.((prev) => ({
      ...prev,
      filters: preparedFilters.filters,
      dateRange: preparedFilters.dateRange
    }))
  }

  const onSearchChange = useCallback(
    (newValue: string | undefined) => {
      setSearchValue(newValue)

      if (!newValue || newValue?.length >= MIN_SEARCH_QUERY_LENGTH) {
        onUpdateRequestDetails?.((prev) => ({
          ...prev,
          search: newValue || '',
          currentPage: 1
        }))
      }
    },
    [onUpdateRequestDetails]
  )

  const providerValue = useMemo(
    () => ({
      filterValues,
      groupBy,
      searchPlaceholder,

      filters,
      groupByOptions,

      isRowHighlighted,
      handleAction,
      onSearchChange,
      setFilterValues: onFilterChange,
      onGroupByChange: setGroupBy
    }),
    [
      filterValues,
      groupBy,
      filters,
      groupByOptions,
      searchPlaceholder,
      onSearchChange,
      handleAction,
      isRowHighlighted,
      onFilterChange
    ]
  )

  const clearFilters = () => {
    onSearchChange('')
    onFilterChange({})
  }

  const handleSort = (property: string) => {
    let newSortValue: TableSortValue | undefined = undefined

    if (sortBy?.field !== property) {
      newSortValue = {
        field: property,
        direction: TableSortDirection.Asc
      }
    } else if (sortBy?.direction === TableSortDirection.Asc) {
      newSortValue = {
        field: property,
        direction: TableSortDirection.Desc
      }
    }

    setSortBy(newSortValue)
    onUpdateRequestDetails?.((prev) => ({
      ...prev,
      sortBy: newSortValue
    }))
  }

  const handlePageChange = (value: number) => {
    onUpdateRequestDetails?.((prev) => ({
      ...prev,
      currentPage: value
    }))

    setPage(value)
  }

  useEffect(() => {
    if (defaultFilters) {
      setFilterValues(defaultFilters)
    }
  }, [defaultFilters])

  return (
    <TableContextProvider value={providerValue}>
      <Col
        items="stretch"
        className="tw-self-stretch tw-w-full background-color-gray0"
      >
        {showFilters && (
          <TableFilters
            savedSearchValue={searchValue}
            showSearch={showSearch}
          />
        )}

        {!hasRows && isFilterApplied && !loading ? (
          <BaseEmptyState
            Icon={<NoSearchResults />}
            primaryText={`No search results ${searchValue ? `for '${searchValue}'` : ''}`}
            descriptionText={
              'Try adjusting your search, checking your spelling, or start a new search.'
            }
            buttonAction={clearFilters}
            buttonText={searchValue ? 'Clear Search' : 'Clear Filters'}
          />
        ) : (
          <div
            className={clsx(styles.tableWrapper, hasRows && styles.withItems)}
          >
            <TableContainer className={styles.tableContainer}>
              {isEmpty ? (
                <>{EmptyScreen || <BaseEmptyState {...noResultsMessage} />}</>
              ) : (
                <Table
                  size="medium"
                  aria-labelledby={name}
                  classes={{
                    root: clsx(
                      styles.table,
                      showFilters && styles.borderTop,
                      clickable && styles.clickable
                    )
                  }}
                >
                  <TableHeader
                    sortBy={sortBy}
                    cols={memoCols}
                    highOrderCols={memoHighOrderCols}
                    handleSort={handleSort}
                  />

                  {hasRows && (
                    <TableBody>
                      {memoRows.map((row) => (
                        <CustomTableRow
                          key={row[idAccessor]}
                          row={row}
                          columns={memoCols}
                          idAccessor={idAccessor}
                          clickable={clickable}
                        />
                      ))}
                    </TableBody>
                  )}
                </Table>
              )}
              {loading && (
                <Col
                  items="center"
                  justify="center"
                  gap={8}
                  className="tw-flex-1 tw-w-full"
                >
                  <Loader />
                </Col>
              )}
            </TableContainer>

            {enablePagination && hasRows && (
              <Footer>
                <TablePagination
                  pagination={pagination}
                  onPageChange={handlePageChange}
                />
              </Footer>
            )}
          </div>
        )}
      </Col>
    </TableContextProvider>
  )
}

export default CustomTable
