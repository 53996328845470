import { navigationItems } from '@/utils/data/navigationItems'
import { withParams } from '@/api'

const getPageTitle = (pathname: string, siteId: string, gateId: string) => {
  let title = ''

  // Search in main items
  navigationItems.some((item) => {
    const parentItemLink = withParams(item.link || '', {
      site_id: siteId,
      gate_id: gateId
    })

    if (parentItemLink === pathname) {
      title = item.title
      return true
    }

    // Search in sub items
    if (Array.isArray(item.items)) {
      return item.items.some((subItem) => {
        const subItemLink = withParams(subItem.link, {
          site_id: siteId,
          gate_id: gateId
        })

        if (subItemLink === pathname) {
          title = subItem.title
          return true
        }

        return false
      })
    }

    return false
  })

  return title
}

export default getPageTitle
