import dayjs, { Dayjs } from 'dayjs'
import { DateRangeFilter } from '@/__generated__/graphql'
import { OLDEST_AVAILABLE_DATE } from '@/constants'
import { TimeUnit } from '@/types/enums/ui'
import { IDateRangeFilterValue } from '@/types/interfaces/table'
import { rangeToDateRangeFilter } from '@/utils/table'
import { getEarliestDateFromList, getLatestDayFromList } from '@/utils/helpers'

const dwellToDateRangeFilter = (
  dateRangeFilterValue: IDateRangeFilterValue | undefined,
  value: Dayjs
): DateRangeFilter => {
  const dateRangeBasedOnDwell: DateRangeFilter = {
    startDate: dayjs(OLDEST_AVAILABLE_DATE)?.toISOString(),
    endDate: dayjs()
      .subtract(value.hour() || 0, TimeUnit.Hour)
      .subtract(value.minute() || 0, TimeUnit.Minute)
      .toISOString()
  }

  // Dwell filter behind the scene updates dateRange filter because API doesn't support it
  // as separate filter, so if some tables contain both dwell and date range filter
  // we need to find intersection based on both of them and filter data based on it
  if (dateRangeFilterValue) {
    const preparedDateRangeFilter = rangeToDateRangeFilter(dateRangeFilterValue)

    dateRangeBasedOnDwell.startDate = getLatestDayFromList([
      dateRangeBasedOnDwell.startDate,
      preparedDateRangeFilter.startDate
    ])

    dateRangeBasedOnDwell.endDate = getEarliestDateFromList([
      dateRangeBasedOnDwell.endDate,
      preparedDateRangeFilter.endDate
    ])
  }

  return dateRangeBasedOnDwell
}

export default dwellToDateRangeFilter
