import { TableFilterType } from '@/types/enums/table'
import {
  dwellToDateRangeFilter,
  listToArrayFilter,
  listToStringFilter,
  rangeToDateRangeFilter,
  referenceIdToStringFilter
} from '@/utils/table/filterRequestFormatters'
import {
  classListSelectOptions,
  emissionTypesSelectOptions,
  fuelTypeSelectOptions,
  isVisitorSelectOptions,
  loadStatusSelectOptions,
  mismatchSelectOptions,
  transactionTypesSelectOptions
} from '@/utils/mappers'
import { DetectionType } from '@/features/gate/enums'
import {
  IFilterDateRange,
  IFilterDwell,
  IFilterList
} from '@/types/interfaces/table'

export const dateRangeFilter: IFilterDateRange = {
  id: 'dateRange',
  name: 'Date Range',
  type: TableFilterType.DateRange,
  requestFormatter: rangeToDateRangeFilter
}

export const transactionDirectionFilter: IFilterList = {
  id: 'transactionType',
  name: 'Direction',
  type: TableFilterType.List,
  placeholder: 'Search Direction',
  requestFormatter: listToStringFilter,
  options: transactionTypesSelectOptions
}

export const eventTypeFilter: IFilterList = {
  id: 'referenceId',
  name: 'Event Type',
  type: TableFilterType.List,
  placeholder: 'Search Type',
  requestFormatter: referenceIdToStringFilter,
  options: [
    { id: DetectionType.Detection, label: 'Detection' },
    { id: DetectionType.Manual, label: 'Manual' }
  ]
}

export const appointmentTypeFilter: IFilterList = {
  id: 'appointmentType',
  name: 'Visitor',
  type: TableFilterType.List,
  placeholder: 'Search Type',
  requestFormatter: listToStringFilter,
  options: isVisitorSelectOptions
}

export const weightClassFilter: IFilterList = {
  id: 'powerUnitWeightClass',
  name: 'Class',
  type: TableFilterType.List,
  placeholder: 'Search Class',
  requestFormatter: listToStringFilter,
  options: classListSelectOptions
}

export const fuelTypeFilter: IFilterList = {
  id: 'powerUnitFuelType',
  name: 'Fuel Type',
  type: TableFilterType.List,
  placeholder: 'Search Fuel Type',
  requestFormatter: listToStringFilter,
  options: fuelTypeSelectOptions
}

export const emissionTypeFilter: IFilterList = {
  id: 'powerUnitEmissionType',
  name: 'Fuel Emission Type',
  type: TableFilterType.List,
  placeholder: 'Search Emission Type',
  requestFormatter: listToStringFilter,
  options: emissionTypesSelectOptions
}

export const loadStatusFilter: IFilterList = {
  id: 'loadStatus',
  name: 'Load Status',
  type: TableFilterType.List,
  placeholder: 'Search Status',
  requestFormatter: listToStringFilter,
  options: loadStatusSelectOptions
}

export const mismatchFilter: IFilterList = {
  id: 'mismatch',
  name: 'Mismatches',
  type: TableFilterType.List,
  placeholder: 'Search Value',
  requestFormatter: listToStringFilter,
  options: mismatchSelectOptions
}

export const dwellFilter: IFilterDwell = {
  id: 'dwell',
  name: 'Dwell',
  type: TableFilterType.Dwell,
  requestFormatter: dwellToDateRangeFilter
}

export const accountFilter = (accounts: string[]): IFilterList => ({
  id: 'accountName',
  name: 'Account',
  type: TableFilterType.List,
  requestFormatter: listToArrayFilter,
  placeholder: 'Search Account',
  options: accounts.map((account) => ({
    id: account,
    label: account
  }))
})
