import SearchIcon from '@mui/icons-material/Search'
import { Collapse } from '@mui/material'
import { FC, useEffect, useMemo, useState } from 'react'

import { Col, Input, Row } from '@/components/atoms'
import { useTableContext } from '@/components/contexts'
import {
  FilterDropdown,
  GroupByDropdown
} from '@/components/organisms/Table/components'

import styles from './TableFilters.module.scss'
import { useDebounceValue } from 'usehooks-ts'
import { DEBOUNCE_TIME } from '@/constants'

interface IProps {
  savedSearchValue?: string | undefined
  showSearch?: boolean
}

const TableFilters: FC<IProps> = (props) => {
  const { savedSearchValue, showSearch = false } = props

  const {
    filters,
    filterValues,
    searchPlaceholder,
    groupByOptions,
    onSearchChange
  } = useTableContext()

  const [searchInputValue, setSearchInputValue] = useState<string | undefined>(
    undefined
  )

  const [debouncedSearchValue] = useDebounceValue(
    searchInputValue,
    DEBOUNCE_TIME
  )

  const showFilters = Array.isArray(filters)
  const showGroupBy = Array.isArray(groupByOptions)

  const isFilterApplied = useMemo(
    () =>
      Object.values(filterValues || {}).some((filterValue) => !!filterValue),
    [filterValues]
  )

  useEffect(() => {
    onSearchChange(debouncedSearchValue || '')
  }, [debouncedSearchValue])

  useEffect(() => {
    if (searchInputValue !== savedSearchValue) {
      setSearchInputValue(savedSearchValue)
    }
  }, [savedSearchValue])

  return (
    <Col>
      <Row
        justify="between"
        gap={20}
        items="center"
        className={styles.tableFilters}
      >
        {(showFilters || showSearch) && (
          <Row gap={8}>
            {showSearch && (
              <Input
                size="small"
                type="text"
                variant="outlined"
                value={searchInputValue}
                onChange={(e) => setSearchInputValue(e.target.value)}
                placeholder={searchPlaceholder}
                className="background-color-gray0"
                icon={<SearchIcon width={16} height={16} />}
              />
            )}

            {showFilters && (
              <FilterDropdown isFilterApplied={isFilterApplied} />
            )}
          </Row>
        )}

        {showGroupBy && <GroupByDropdown />}
      </Row>

      <Collapse
        unmountOnExit={false}
        orientation="vertical"
        timeout={400}
        in={isFilterApplied}
      >
        <div id="filters-portal" className="tw-overflow-y-auto" />
      </Collapse>
    </Col>
  )
}

export default TableFilters
