import { KeyCode } from '@/types/enums/ui'
import { capitalize } from 'lodash'

const FIELDS_REQUIRING_UPPERCASE_HIGHLIGHT = ['id', 'lpn']

const snakeCaseToFriendlyString = (variableName: string) =>
  variableName
    .split(KeyCode.Underscore)
    .map((part) =>
      // Some fields, like "id" and other shorthand or acronyms, should be uppercased when presenting to the user.
      FIELDS_REQUIRING_UPPERCASE_HIGHLIGHT.includes(part)
        ? part.toUpperCase()
        : capitalize(part)
    )
    .join(KeyCode.Space)

export default snakeCaseToFriendlyString
