import { isRejectedWithValue } from '@reduxjs/toolkit'
import { Middleware } from 'redux'

import { HttpMethod } from '@/api/enums'
import { showSnackbar } from '@/features/snackbars-queue/store'
import { ApiResponse } from '@/types/interfaces/api'
import { convertErrorMessageToFriendlyFormat } from '@/api/helpers'

const DEFAULT_ERROR_MESSAGE = 'Something went wrong'
const MUTATION_METHODS = [
  HttpMethod.POST,
  HttpMethod.PUT,
  HttpMethod.PATCH,
  HttpMethod.DELETE
]

export const errorMiddleware: Middleware =
  (middlewareApi) => (next) => (action) => {
    const { dispatch } = middlewareApi

    const { meta } = action as {
      meta: { baseQueryMeta: { request: { method: HttpMethod } } }
    }
    const method = meta?.baseQueryMeta?.request?.method

    if (isRejectedWithValue(action) && MUTATION_METHODS.includes(method)) {
      const payload = action.payload as ApiResponse<any>

      const errors = payload?.data?.errors
      const firstError = Array.isArray(errors) ? errors[0] : errors

      dispatch(
        showSnackbar({
          message: convertErrorMessageToFriendlyFormat(
            firstError?.message || firstError || DEFAULT_ERROR_MESSAGE
          ),
          options: {
            variant: 'error'
          }
        })
      )
    }

    return next(action)
  }
