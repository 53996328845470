import { FC } from 'react'
import { useFormContext } from 'react-hook-form'

import {
  Chip,
  Col,
  Input,
  ReadOnlyFormValue,
  Row,
  Text
} from '@/components/atoms'
import { useSsoEnabled } from '@/features/home/hooks/useSsoEnabled'
import { SsoEnabledState } from '@/features/home/types/sso'
import { IUser } from '@/features/user-management/types'
import { EditUserSchemaType } from '@/features/user-management/utils'
import { Color } from '@/styles/palette'
import { TextTypes } from '@/types/enums/ui'
import SsoRequiredBadge from '../SsoRequired/SsoRequiredBadge'

interface IProps {
  user: IUser | undefined
}

const ProfileDetailsFormSection: FC<IProps> = (props) => {
  const { user } = props
  const { disabled, id } = user || {}

  const editMode = !!id

  const { ssoEnabled } = useSsoEnabled()

  const {
    register,
    watch,
    formState: { errors }
  } = useFormContext<EditUserSchemaType>()

  return (
    <Col gap={8} items="stretch">
      {!editMode && (
        <Text
          type={TextTypes.TEXT_MD}
          color={Color.gray700}
          className="tw-mb-8"
        >
          Enter the email address of the user you want to invite. Each invite is
          specific to an email address and will expire after <b>7 days</b>.
        </Text>
      )}

      <Row gap={8}>
        <Input
          {...register('first_name')}
          fullWidth
          required
          label="First Name"
          error={!!errors.first_name}
          helperText={errors.first_name?.message}
        />
        <Input
          {...register('last_name')}
          fullWidth
          required
          label="Last Name"
          error={!!errors.last_name}
          helperText={errors.last_name?.message}
        />
      </Row>

      {editMode ? (
        <>
          <ReadOnlyFormValue
            required
            biggerGap
            title="Email"
            value={watch('email')}
            className="tw-mt-6"
          />

          <ReadOnlyFormValue
            required
            biggerGap
            title="Status"
            value={
              <Chip
                className="tw-self-start"
                type={disabled ? 'default' : 'success'}
                label={disabled ? 'Inactive' : 'Active'}
              />
            }
            className="tw-mt-6"
          />
        </>
      ) : (
        <Input
          {...register('email')}
          required
          label="Email"
          type="email"
          error={!!errors.email}
          helperText={errors.email?.message}
        />
      )}
      {ssoEnabled === SsoEnabledState.Enabled && <SsoRequiredBadge />}
    </Col>
  )
}

export default ProfileDetailsFormSection
